// importing require modules
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(_ => import('../pages/Home'));

const Planes = lazy(_ => import('../pages/Planes'));

const Equipo = lazy(_ => import('../pages/Equipo'));

const ContactPage = lazy(_ => import('../pages/ContactPage'));


export default function routes() {
    return (

        <Suspense >

            {/* Route configuration */}
            <Routes>

                {/* Route for homepage */}
                <Route path="" element={<Home />} />

                <Route path="/planes" element={<Planes />} />

                <Route path="/equipo" element={<Equipo />} />

                <Route path="/contacta" element={<ContactPage />} />

            </Routes>

        </Suspense>
    );
}
