import { useEffect } from 'react';
import './App.css';
import { Footer, Header } from './layouts';
import routes from './routes/routes';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div className="App">

      <Header />

      {routes()}

      <Footer />

    </div>
  );
}

export default App;
