import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'

export default function MobileMnue({ isExpended }) {
    return (
        <div className={`bg-white lg:hidden shadow p-5 w-full absolute left-0 top-16 transition-all z-[10000] ${isExpended ? "opacity-100 scale-100 visible" : "opacity-0 scale-0 invisible"}`}>

            <ul className='flex flex-col lg:hidden items-center justify-between'>

                <li className='mx-2 mb-5 border-b border-b-[#58291E] w-full text-center pb-5'>

                    <NavLink to={'/'} className={` text-black p-1 px-7 font-[400]`}>Incio</NavLink>

                </li>
                <li className='mx-2 mb-5 border-b border-b-[#58291E] w-full text-center pb-5'>

                    <NavLink to={'/planes'} className={` text-black p-1 px-7 font-[400]`}>Planes</NavLink>

                </li>
                <li className='mx-2 mb-5 border-b border-b-[#58291E] w-full text-center pb-5'>

                    <NavLink to={'/equipo'} className={` text-black p-1 px-7 font-[400]`}>Equipo</NavLink>

                </li>
                <li className='mx-2 mb-5 border-b border-b-[#58291E] w-full text-center pb-5'>

                    <NavLink to={'/contacta'} className={` text-black p-1 px-7 font-[400]`}>Contacta</NavLink>

                </li>

            </ul>

            <div className='flex items-center justify-center'>

                <button className='bg-transparent flex lg:hidden items-center text-[#45B8EA] border border-[#45B8EA] font-medium me-3 p-3 px-8 rounded-[30px] '>

                    <FontAwesomeIcon icon={faArrowDown} />

                    <span className='ms-3'>
                        Descargar App
                    </span>

                </button>

            </div>

        </div>
    )
}
