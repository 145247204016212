import { faArrowDown, faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import MobileMnue from './MobileMnue';

export default function Header() {

    const [isScrollingOut, setIsScrollingOut] = useState(false);

    const [changeBg, setChangeBg] = useState(false);

    const [isExpended, setIsExpended] = useState(false);

    const handelScrolling = () => {
        if (window.scrollY > 800) {

            setChangeBg(true);

        } else {

            setChangeBg(false);

        }

        if (window.scrollY > 10) {

            setIsScrollingOut(true);

        } else {

            setIsScrollingOut(false);

        }
    }


    useEffect(() => {

        window.addEventListener('scroll', () => {

            // Handel Scrolling For Just Home Page
            if (window.location.pathname === "/") {

                handelScrolling();

            }

        })

    }, []);

    return (
        <header className={`p-6 transition ${isScrollingOut ? "fixed" : "relative"} w-full z-[2222222222] ${changeBg ? "bg-white shadow-lg " : "bg-[#583835]"}`}>

            <div className='container relative p-1 lg:px-10 rounded-[200px] flex items-center justify-between'>

                <Link to={''}>
                    <img src={require(`../../assests/images/${changeBg ? "dark-logo" : "logo"}.png`)} />
                </Link>

                <ul className='hidden lg:flex items-center justify-between'>

                    <li className='mx-2'>

                        <NavLink to={'/'} className={`${changeBg ? "text-black" : "text-white"} p-1 px-7 font-[400]`}>Incio</NavLink>

                    </li>
                    <li className='mx-2'>

                        <NavLink to={'/planes'} className={`${changeBg ? "text-black" : "text-white"} p-1 px-7 font-[400]`}>Planes</NavLink>

                    </li>
                    <li className='mx-2'>

                        <NavLink to={'/equipo'} className={`${changeBg ? "text-black" : "text-white"} p-1 px-7 font-[400]`}>Equipo</NavLink>

                    </li>
                    <li className='mx-2'>

                        <NavLink to={'/contacta'} className={`${changeBg ? "text-black" : "text-white"} p-1 px-7 font-[400]`}>Contacta</NavLink>

                    </li>

                </ul>

                <button className='bg-[#43B8EA] hidden lg:flex items-center text-white font-medium me-3 p-3 px-8 rounded-[30px] '>

                    <FontAwesomeIcon icon={faArrowDown} />

                    <span className='ms-3'>
                        Descargar App
                    </span>

                </button>

                <FontAwesomeIcon onClick={() => setIsExpended(!isExpended)} icon={isExpended ? faClose : faBars} className={`block lg:hidden text-[40px] ${changeBg ? "text-black" : "text-white"} cursor-pointer`} />

                <MobileMnue isExpended={isExpended} />

            </div>

        </header>
    )
}
